<template>
  <div>
    <b-row class="justify-content-center">
      <alerta-login />
      <b-col cols="12" lg="9" xl="12">
        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.NOVA_SENHA") }}
        </h4>

        <input-text
          ref="novaSenha"
          v-model="form.novaSenha"
          noLabel
          type="password"
          :placeholder="$t('GERAL.NOVA_SENHA')"
          :minLength="8"
          required
          @input="verificarForcaDaSenha"
        />
        <verifica-forca-senha
          embaixo
          :value="forcaSenha"
        ></verifica-forca-senha>

        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.CONFIRMAR_NOVA_SENHA") }}
        </h4>

        <input-text
          ref="novaConfirmacaoSenha"
          v-model="form.novaConfirmacaoSenha"
          noLabel
          type="password"
          :label="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
          :placeholder="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
          :validate="validarSenha"
          :minLength="8"
          required
        />

        <b-button
          variant="primary"
          size="lg"
          block
          class="mt-4 input-autenticacao"
          @click="alterarSenha"
          >{{ $t("GERAL.TROCAR_SENHA") }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import UsuarioService from "@/common/services/usuario/usuario.service";
import InputText from "@/components/inputs/InputText";
import AlertaLogin from "./AlertaLogin.vue";
import VerificaForcaSenha from "@/components/outros/VerificaForcaSenha.vue";
export default {
  name: "TrocarSenha",
  components: {
    InputText,
    AlertaLogin,
    VerificaForcaSenha,
  },
  props: {
    loginErro: Function,
  },
  data() {
    return {
      form: {
        novaSenha: null,
        novaConfirmacaoSenha: null,
      },
      mostrarModal: false,
      noError: true,
      forcaSenha: "",
    };
  },
  computed: {
    validarSenha() {
      return this.form.novaSenha === this.form.novaConfirmacaoSenha;
    },
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.novaConfirmacaoSenha.valid());
      arValidation.push(this.$refs.novaSenha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      this.form.usuarioId = localStorage.getItem("usuario_id");
      UsuarioService.alterarSenha(this.form)
        .then((result) => {
          if (result) {
            this.$router.push({ name: "operacoes" });
          }
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
    saibaMais() {
      window.location.href =
        "https://www.valorem.com.br/antecipacao-de-recebiveis/";
    },
    verificarForcaDaSenha() {
      let senha = this.form.novaSenha;
      UsuarioService.verificarForcaDaSenha(senha).then((result) => {
        this.forcaSenha = result.data.forcaSenhaEnum;
      });
    },
  },
};
</script>

<style lang="scss">
.input-autenticacao {
  height: 60px !important;
}
</style>
